
























































/*
интерфейс состояния контролов {
  time
  terminal
  dayOff
}

Есть 4 состояния контролов

1. пустое
2. локальное
3. внешнее(computed)
3. отображаемое(computed)


Внешнее состояние генерится из (computed by props: time, computed by props: dayOff, store:terminal)
На изменения контролов - меняем локальное состояние
На изменение внешнего состояния мы меняем локальное

Отображение:
  Если у нас в локальном стейте выходной - отображаем пустое состояние
  Если не выходной - отображаем локальное

На сабмит:
  Если в локальном выходной
    Если во внешнем выходной - сбрасываем локальное до внешнего(вдруг там время или терминал поменялись)
    Иначе - пушим удаление записи
  Если не выходной
    Если запись уже есть - пушим обновление
    Если нет - пушим создание
*/

import Vue from "vue";
import Close_svg from "@/assets/svg/close.svg?inline";
import { Input, Checkbox, Select, Button } from "@/ui";
import Wrap from "./Wrap.vue";
import { TerminalWithStatus, ScheduleRecord, Employee } from "@/schemas/dashboard";
import { Moment } from "moment";
import { ScheduleModule } from "@/store/modules/schedule";
import { TerminalModule } from "@/store/modules/terminal";
import { EmployeeSearchModule } from "../../store/modules/employeeSearch";
import { UserModule } from "../../store/modules/user";

const getDifference = (
  obj1: { [p: string]: any },
  obj2: { [p: string]: any }
): { [p: string]: any } => {
  const keys = [...Object.keys(obj1), ...Object.keys(obj2)];
  return keys.reduce((finalObj, key) => {
    if (obj1[key] !== obj2[key]) {
      finalObj[key] = obj2[key] !== undefined ? obj2[key] : obj1[key];
    }
    return finalObj;
  }, {} as { [p: string]: any });
};

interface ControlState {
  terminal: TerminalWithStatus | null;
  time: string;
  dayOff: boolean;
}

interface IComponentData {
  controlState: ControlState;
  [propName: string]: any;
}

export default Vue.extend({
  components: {
    Close_svg,
    cInput: Input,
    cCheckbox: Checkbox,
    cSelect: Select,
    cButton: Button,
    Wrap
  },
  data(): IComponentData {
    const controlStateEmpty = {
      terminal: null,
      time: "",
      dayOff: true
    };
    return {
      validationEnable: false,
      showFailMessage: false,
      controlState: controlStateEmpty,
      controlStateEmpty
    };
  },
  props: {
    employeeId: {
      type: Number,
      required: true
    },
    record: Object as () => ScheduleRecord, //can be undefined
    day: {
      type: Object as () => Moment,
      required: true
    },
    value: Boolean
  },
  computed: {
    userStore(): UserModule {
      return this.$cStore.user;
    },
    scheduleStore(): ScheduleModule {
      return this.$cStore.schedule;
    },
    terminalStore(): TerminalModule {
      return this.$cStore.terminal;
    },
    employeeSearchStore(): EmployeeSearchModule {
      return this.$cStore.employeeSearch;
    },
    employee(): Employee | undefined {
      return this.employeeSearchStore.getEmployee(this.employeeId);
    },
    terminals(): TerminalWithStatus[] {
      return this.terminalStore.terminals;
    },
    employeeAvailableTerminals(): TerminalWithStatus[] {
      const employee = this.employee;
      if (employee === undefined) return [];
      return this.terminals.filter(terminal => employee.terminals.includes(terminal.id));
    },
    submitDisabled(): boolean {
      return (
        (!this.controlState.dayOff && this.validationEnable && !this.formValid) ||
        !this.userStore.userCanEdit
      );
    },
    selectedTimeValid(): boolean {
      let stringValid = /[0-9][0-9]:[0-9][0-9] - [0-9][0-9]:[0-9][0-9]/.test(
        this.controlState.time
      );
      if (!stringValid) return false;
      let tN = this.getSelectedTimeNumbers();
      return tN[0] < 24 && tN[1] < 60 && tN[2] < 24 && tN[3] < 60;
    },
    selectedTerminalValid(): boolean {
      return this.controlState.terminal !== null;
    },
    formValid(): boolean {
      return this.selectedTimeValid && this.selectedTerminalValid;
    },
    displayedState(): ControlState {
      return this.controlState.dayOff ? this.controlStateEmpty : this.controlState;
    },
    outerTerminal(): TerminalWithStatus | null {
      let terminal: TerminalWithStatus | undefined;
      if (this.record) {
        terminal = this.employeeAvailableTerminals.find(
          terminal => terminal.id === this.record.terminal
        );
      } else if (this.terminalStore.checkReal()) {
        terminal = this.terminalStore.activeTerminal;
      }
      return terminal ? terminal : null;
    },
    outerTime(): string {
      const terminal = this.outerTerminal;
      if (!this.outerTerminal || !this.record) return "";
      const start = this.$moment(this.record.start_time)
        .tz(this.outerTerminal.timezone)
        .format("HH:mm");
      const end = this.$moment(this.record.end_time)
        .tz(this.outerTerminal.timezone)
        .format("HH:mm");
      return `${start} - ${end}`;
    },
    outerDayOff(): boolean {
      return this.record ? false : true;
    },
    outerControlState(): ControlState {
      return {
        time: this.outerTime,
        dayOff: this.outerDayOff,
        terminal: this.outerTerminal
      };
    }
  },
  methods: {
    async submit() {
      //TODO: если время окончания меньше времени старта, то окончание переносить на следующий день
      this.showFailMessage = false;

      try {
        if (this.controlState.dayOff) {
          if (!this.outerControlState.dayOff) {
            await this.removeRecord();
          } else {
            this.zeroingControls("outer"); // обнуляем до последних внешних изменений
          }
        } else {
          this.validationEnable = true;
          if (!this.formValid) return;
          if (this.outerControlState.dayOff) {
            await this.createRecord();
          } else {
            await this.updateRecord();
          }
          this.validationEnable = false;
        }
        this.close();
      } catch (err) {
        console.log(err);
        this.showFailMessage = true;
      }
    },
    async createRecord() {
      const newRecord = this.buildNewRecord();
      if (!newRecord) throw "Operation failed";
      const success = await this.scheduleStore.createRecord(newRecord);
      if (!success) throw "Operation failed";
    },
    async updateRecord() {
      const newRecord = this.buildNewRecord();
      if (!newRecord || !this.record) throw "Operation failed";
      const success = await this.scheduleStore.updateRecord({
        id: this.record.id,
        record: newRecord
      });
      if (!success) throw "Operation failed";
    },
    async removeRecord() {
      if (!this.record) throw "Operation failed";
      const success = await this.scheduleStore.removeRecord(this.record.id);
      if (!success) throw "Operation failed";
    },
    buildNewRecord(): Omit<ScheduleRecord, "id"> | false {
      if (!this.selectedTimeValid || !this.selectedTerminalValid || !this.controlState.terminal)
        return false; //тупо что нельзя guards закинуть здесь
      const [start_time, end_time] = this.parseStringTime(this.controlState.terminal.timezone);
      return {
        start_time,
        end_time,
        terminal: this.controlState.terminal.id,
        employee: this.employeeId
      };
    },
    parseStringTime(timezone: string): [string, string] {
      const tN = this.getSelectedTimeNumbers();
      const tzDay = this.day.clone().tz(timezone, true);
      const start_time = tzDay
        .hours(tN[0])
        .minutes(tN[1])
        .startOf("minute")
        .toISOString();
      const end_time = tzDay
        .hours(tN[2])
        .minutes(tN[3])
        .startOf("minute")
        .toISOString();

      const tzFormatted = tzDay.format();
      const dayFormatted = this.day.format();
      return [start_time, end_time];
    },
    ISO_time_to_tz(isoTime: string, terminalId: number): string {
      const terminal = this.employeeAvailableTerminals.find(terminal => terminal.id === terminalId);
      if (!terminal) return "";
      return this.$moment(isoTime)
        .tz(terminal.timezone)
        .format("HH:mm");
    },
    getSelectedTimeNumbers() {
      if (!this.controlState.time) return [];
      return this.controlState.time
        .replace(" - ", ":")
        .split(":")
        .map(t => parseInt(t));
    },
    zeroingControls(to?: string) {
      let toState: ControlState;
      switch (to) {
        case "outer":
          toState = this.outerControlState;
          break;
        case "empty":
        default:
          toState = this.controlStateEmpty;
          break;
      }
      this.setControls(toState);
    },
    setControls(controlState: Partial<ControlState>) {
      this.controlState = { ...this.controlState, ...controlState };
    },
    close() {
      this.$emit("input", false);
    }
  },
  watch: {
    outerControlState: {
      handler(newState, oldState) {
        //передаем только отличия,
        //чтобы не перебивать локальные изменения без надобности
        //(локально изменили время, потом глобально изменили терминал => перетерлось и время и терминал локально)
        const difference = oldState ? getDifference(oldState, newState) : newState;
        this.setControls(difference);
      },
      immediate: true
    },
    value(isOpen) {
      if (isOpen) {
        this.validationEnable = false;
        this.showFailMessage = false;
        this.zeroingControls("outer");
      }
    }
  }
});
