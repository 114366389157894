































//TODO: поресерчить как ускорить рендер таблицы
import Vue from "vue";
import { Moment } from "moment";
import { ScheduleRecord, Employee } from "@/schemas/dashboard";
import { TerminalModule } from "@/store/modules/terminal";
import { ScheduleModule } from "@/store/modules/schedule";
import { EmployeeSearchModule } from "@/store/modules/employeeSearch";
import { getSchedule, GetScheduleProps, exportSchedule, getEmployees } from "@/api/dashboard";
import { orderBy } from "lodash";
import { TableText, TableLine, TableWrap } from "@/ui/Table";
import employee from "@/components/Employee.vue";

import { Tab, Loader } from "@/ui";
import RowLayout from "./RowLayout.vue";
import TableHeader from "./Header.vue";
import TableRow from "./Row.vue";

interface TooltipOpenedIds {
  rowId: number;
  colId: number;
}

interface ComponentData {
  openedTooltips: TooltipOpenedIds[];
  allEmployees: Employee[];
  [propName: string]: any;
}

export type TableCell = ScheduleRecord | null;

export interface TableRow {
  employee: Employee;
  records: TableCell[];
}

export default Vue.extend({
  components: {
    TableWrap,
    TableHeader,
    TableRow,
    TableLine,
    Loader
  },
  data: (): ComponentData => ({
    openedTooltips: [],
    weeksCounter: 0,
    maxWeeksCounter: 4,
    minWeeksCounter: -4,
    employeeLoading: true,
    allEmployees: [],
    openedTooltip: null,
    openedRow: null
  }),
  computed: {
    scheduleStore(): ScheduleModule {
      return this.$cStore.schedule;
    },
    searchStore(): EmployeeSearchModule {
      return this.$cStore.employeeSearch;
    },
    termStore(): TerminalModule {
      return this.$cStore.terminal;
    },
    scheduleRecords(): ScheduleRecord[] {
      return this.scheduleStore.shceduleRecords;
    },
    fromDate(): ScheduleModule["fromDate"] {
      return this.scheduleStore.fromDate;
    },
    tillDate(): ScheduleModule["tillDate"] {
      return this.scheduleStore.tillDate;
    },
    dateRange(): Moment[] {
      let dates = [this.fromDate];
      for (let i = 1; i <= 6; i++) {
        let nextDay = this.fromDate.clone().add(i, "days");
        dates.push(nextDay);
      }
      return dates;
    },
    tableSchedulesGrouped(): TableRow[] {
      return this.availableEmployees.map(employee => {
        const records = this.getEmployeeRecords(employee);
        return {
          employee,
          records
        };
      });
    },
    activeTerminalId(): number | undefined {
      return this.termStore.terminalId;
    },
    tableLoading(): boolean {
      return this.employeeLoading || !this.termStore.isReady || this.scheduleStore.isLoading;
    },
    filteredEmployees(): Employee[] {
      return this.searchStore.filteredEmployees;
    },
    availableEmployees(): Employee[] {
      return orderBy(this.filteredEmployees, [r => this.concatName(r)]);
    }
  },
  methods: {
    async fetchSchedule() {
      await this.scheduleStore.getSchedule({
        // terminal: this.activeTerminalId,
        from_date: this.fromDate,
        till_date: this.tillDate
      });
    },
    async fetchEmployees() {
      if (!this.termStore.checkReady()) return;
      this.employeeLoading = true;
      this.allEmployees = await getEmployees(this.activeTerminalId);
      this.employeeLoading = false;
    },
    checkTooltipOpen(rowId: number, colId: number): boolean {
      return (
        this.openedTooltips.find(tooltip => tooltip.rowId === rowId && tooltip.colId === colId) !==
        undefined
      );
    },
    tooltipChange(isOpen: boolean, rowId: number, colId: number) {
      let savedState = this.checkTooltipOpen(rowId, colId);
      if (isOpen === savedState) return;

      if (isOpen) {
        this.openedTooltips.push({ rowId, colId });
      } else {
        this.openedTooltips = this.openedTooltips.filter(
          idsPair => idsPair.rowId !== rowId && idsPair.colId !== colId
        );
      }
    },
    getEmployeeRecords(employee: Employee): TableRow["records"] {
      return this.dateRange.map(dateInRange => {
        const scheduleRecord = this.scheduleRecords.find(record => {
          if (record.employee !== employee.id) return false;
          const terminal = this.termStore.getTerminal(record.terminal);
          if (!terminal) return false;
          const recordDate = this.$moment(record.start_time).tz(terminal.timezone);
          return recordDate.format("YYYY-MM-DD") === dateInRange.format("YYYY-MM-DD");
        });
        return scheduleRecord ? scheduleRecord : null;
      });
    },
    concatName(employee: Employee): string {
      return this.searchStore.concatEmployeeName(employee);
    }
  },
  mounted() {
    this.fetchSchedule();
    this.fetchEmployees();
  },
  watch: {
    async fromDate(newVal) {
      await this.fetchSchedule();
    },
    allEmployees(allEmployees: Employee[]) {
      this.searchStore.SET_ALL_EMPLOYEES(allEmployees);
      // this.searchStore.SET_SUGGESTIONS();
    },
    "termStore.activeTerminal": function(newVal) {
      this.fetchEmployees();
    }
  }
});
