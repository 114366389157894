
import Vue, { VNode } from "vue";
import resizeSensor from "vue-resize-sensor";

/*
  компонент получает/слушает индекс от родителя всегда, на его обновление - обновлет внутренний индекс

  если есть опция handleClick=true; тогда кликая на табы, компонент меняет свой индекс


  слоты могут менятся, тут 2 варианта работы компонента:
    1. autoMove:true; Обновлять индекс на ближайший(было 5 элемент, активный 5ый, стало 3, активный стал 3ий)(нет евента)
    2. Просто выкидываем евент о том что количество текущий индекс вышел за пределы, тогда у родителя будет возможность отловить и скрыть линию(showLine=false)

  Если родитель передает индекс больше чем есть элементов и стоит autoMove:false, компонент так же выдаст евент
*/
export default Vue.extend({
  name: "TabsWrapper",

  props: {
    className: String,
    defaultActiveIndex: Number,
    handleClick: {
      type: Boolean,
      default: true
    },
    autoMove: {
      type: Boolean,
      default: true
    },
    showLine: {
      type: Boolean,
      default: true
    },
    hadeIfInvalid: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    selectedIndex: 0,
    invalidIndex: false,
    lineWidth: 0,
    lineLeft: 0
  }),

  components: {
    resizeSensor
  },

  methods: {
    getTabs() {
      return this.$el.querySelectorAll(".tabs__item");
    },
    //проверяем пришедший индекс, если он не валидный(если есть autoMove - двигаем, если нет - выкидываем евент,ставим метку об инвалидности)
    //если вылидный - просто ставим, двигаем линию, сбарсываем метку об инвалидности
    setIndex(index: number) {
      let validIndex = this.getValidIndex(index);
      if (validIndex !== index) {
        if (this.autoMove) {
          this.selectedIndex = validIndex;
          this.setLine(validIndex);
        } else {
          this.$emit("invalidIndex", index);
          this.invalidIndex = true;
        }
      } else {
        this.setLine(validIndex);
        this.selectedIndex = validIndex;
        this.invalidIndex = false;
      }
    },
    //на ресайз проверяем текущий индекс
    resize() {
      this.setIndex(this.selectedIndex);
    },

    //проверяем на выход за границы
    getValidIndex(index: number) {
      let tabs = this.getTabs();
      return Math.min(index, tabs.length - 1);
    },
    setLine(index: number) {
      let tabs = this.getTabs();
      let activeEl = tabs[index] as HTMLElement; //хотя может конечно и svg прийти
      this.lineLeft = activeEl.getBoundingClientRect().left - this.$el.getBoundingClientRect().left;
      this.lineWidth = activeEl.offsetWidth;
    }
  },

  mounted() {
    if (this.defaultActiveIndex) {
      this.setIndex(this.defaultActiveIndex);
    }
  },

  render(): VNode {
    let tabList = this.$slots.default || [];
    let parentClass = `tabs ${this.className ? this.className : ""}`;
    let itemClass = `tabs__item ${this.className ? this.className + "__item" : ""}`;
    let lineClass = `tabs__line ${this.className ? this.className + "__line" : ""}`;
    let hideLine = (this.hadeIfInvalid && this.invalidIndex) || !this.showLine;
    return (
      <div class={parentClass}>
        {tabList.map((tab, index) => (
          <span class={itemClass} onClick={() => (this.handleClick ? this.setIndex(index) : "")}>
            {tab}
            <resizeSensor onResize={() => this.resize()} />
          </span>
        ))}

        <div
          class={lineClass}
          style={{ width: this.lineWidth + "px", transform: `translateX(${this.lineLeft}px)` }}
          v-show={!hideLine}
        ></div>
      </div>
    );
  },

  watch: {
    defaultActiveIndex(to: number) {
      this.setIndex(this.defaultActiveIndex);
    },
    selectedIndex(to: number) {
      this.$emit("change", to);
    }
  }
});
