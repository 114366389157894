var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-popover',_vm._b({staticClass:"terminal",class:{
    'terminal-active': _vm.active,
    'terminal-prevActive': _vm.prevActive,
    'terminal-nextActive': _vm.nextActive,
    'terminal-hover': _vm.hover
  },attrs:{"disabled":!_vm.terminal.last_ping}},'v-popover',_vm.tooltipOptions,false),[_c('div',{staticClass:"terminal__wrap"},[_c('span',{staticClass:"terminal__status terminal__circle",class:{
        'terminal__circle-red': !_vm.terminal.is_online && !_vm.specialTerminal,
        'terminal__circle-blue': _vm.specialTerminal
      },attrs:{"data-testid":"terminalStatus"}}),_c('p',{staticClass:"terminal__name terminal__text"},[_vm._v(_vm._s(_vm.terminal.name))]),_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.showUsers)?_c('div',{staticClass:"terminal__users flex flex-a_c"},[_c('div',{staticClass:"terminal__userGroup terminal__userGroup-valid flex flex-a_c"},[_c('span',{staticClass:"terminal__circle terminal__circle-small terminal__circle-green"}),_c('p',{staticClass:"terminal__text"},[_vm._v(_vm._s(_vm.terminal.employees_online))])]),_c('div',{staticClass:"terminal__userGroup terminal__userGroup-invalid flex flex-a_c"},[_c('span',{staticClass:"terminal__circle terminal__circle-small terminal__circle-red"}),_c('p',{staticClass:"terminal__text"},[_vm._v(_vm._s(_vm.terminal.employees_offline))])])]):_vm._e()]),_c('span',{staticClass:"terminal__shadow"})],1),(_vm.terminal.last_ping)?_c('template',{slot:"popover"},[_c('TerminalItemTooltip',{attrs:{"time":new Date(_vm.terminal.last_ping)}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }