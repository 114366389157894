



































//TODO: переделать показ/скрытыие тултипа, его начальный рендеринг сильно тормозит
import Vue from "vue";
import { TableCell } from "./Table.vue";
import { Moment } from "moment";
import { ScheduleTooltip } from "@/components/Tooltips";
import { TableText } from "@/ui/Table";
export default Vue.extend({
  props: {
    cell: {
      type: Object as () => TableCell
    },
    day: {
      type: Object as () => Moment,
      required: true
    },
    employeeId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    tooltipOpened: false,
    disabled: true,
    contentReady: false
  }),
  beforeMount() {
    this.disabled = this.detectDisabled();
  },
  mounted() {
    this.contentReady = true;
  },
  computed: {
    differentTerminal(): boolean {
      return (
        !!this.cell &&
        this.$cStore.terminal.terminalId !== undefined &&
        this.cell.terminal !== this.$cStore.terminal.terminalId
      );
    }
  },
  methods: {
    ISO_time_to_tz(isoTime: string, terminalId: number): string {
      const terminal = this.$cStore.terminal.terminals.find(terminal => terminal.id === terminalId);
      if (!terminal) return "";
      return this.$moment(isoTime)
        .tz(terminal.timezone)
        .format("HH:mm");
    },
    detectDisabled(): boolean {
      let recordDay = this.day.startOf("day");
      let nowDay = this.$moment().startOf("day");
      return recordDay.diff(nowDay) < 0;
    }
  },
  components: {
    ScheduleTooltip,
    TableText
  }
});
