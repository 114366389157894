










































import { ITerminalUsers } from "@/types/terminal";
import { TerminalItemTooltip } from "@/components/Tooltips";
import { TerminalWithStatus } from "@/schemas/dashboard";

export default {
  components: {
    TerminalItemTooltip
  },
  props: {
    active: Boolean,
    prevActive: Boolean,
    nextActive: Boolean,
    terminal: {
      type: Object as () => TerminalWithStatus,
      required: true
    },
    specialTerminal: {
      default: false,
      type: Boolean
    },
    showUsers: Boolean,
    hover: Boolean
  },
  data: () => ({
    foo: 1,
    tooltipOptions: {
      delay: { hide: 0, show: 100 },
      autoHide: false,
      placement: "left",
      trigger: "hover",
      boundariesElement: "window",
      arrowSelector: "",
      offset: 10
    }
  })
};
