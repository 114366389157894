








































import TabsWrapper from "@/components/Navigation.vue";
import { ProfileTooltip } from "@/components/Tooltips";
import Vue from "vue";

export default Vue.extend({
  components: {
    TabsWrapper,
    ProfileTooltip
  },
  data: () => ({
    activeNavIndex: 0,
    navLinks: [
      { text: "Онлайн", to: { name: "online" } },
      { text: "Расписание", to: { name: "schedule" } },
      { text: "Отчеты", to: { name: "reports" } }
    ],
    userTooltipOptions: {
      placement: "bottom-end",
      offset: 10
    },
    tooltipOpened: false
  }),
  computed: {
    username(): string | false {
      return this.$cStore.user.profile ? this.$cStore.user.profile.name : false;
    }
  },
  mounted() {
    this.updateNavIndex();
    this.$cStore.user.getProfile();
  },
  methods: {
    updateNavIndex() {
      let index = this.navLinks.findIndex(link => link.to.name === this.$route.name);
      if (index !== -1) {
        this.activeNavIndex = index;
      }
    }
  },
  watch: {
    $route(to) {
      this.updateNavIndex();
    }
  }
});
