
















import Vue from "vue";
import Header from "@/components/Header.vue";
import Terminals from "@/components/Terminals.vue";
export default Vue.extend({
  components: {
    Header,
    Terminals
  }
});
