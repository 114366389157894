















import Vue from "vue";
import cLink from "@/ui/Link.vue";
import Wrap from "./Wrap.vue";
import { DashboardUser } from "@/schemas/dashboard";

export default Vue.extend({
  computed: {
    userProfile(): DashboardUser | null {
      return this.$cStore.user.profile;
    },
    role(): string {
      const mapRoleToRoleName = {
        viewer: "Просмотр",
        admin: "Администрирование",
        editor: "Редактирование"
      };
      const role = this.userProfile?.role;
      return role ? mapRoleToRoleName[role] : "";
    }
  },
  methods: {
    logout() {
      this.$cStore.user.logout();
      this.$router.push("/login");
    },
    openChangeEmailModal() {
      this.$emit("input", false);
      this.$modal.show("profile");
    }
  },
  components: {
    cLink,
    Wrap
  }
});
