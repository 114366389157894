

















import Vue from "vue";
import { ExportButton } from "@/ui";
import { Moment } from "moment";
import { exportSchedule } from "@/api/dashboard";
import { TerminalModule } from "@/store/modules/terminal";

import { IDownloadButtonTooltips } from "@/types/DownloadButton.ts";
import { Employee } from "../schemas/dashboard";

export default Vue.extend({
  components: {
    ExportButton
  },
  props: {
    from_date: {
      type: Object as () => Moment,
      required: true
    },
    till_date: {
      type: Object as () => Moment,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    isError: false
  }),
  computed: {
    terminalId(): TerminalModule["terminalId"] {
      return this.$cStore.terminal.terminalId;
    },
    searchFilled(): boolean {
      return this.$cStore.employeeSearch.selectedSuggestions.length > 0;
    },
    downloadTooltip(): IDownloadButtonTooltips[] {
      if (this.scheduleDisabled && this.badgeDisabled) return [];

      const currentMonth = this.$moment().format("MMMM");
      const nextMonth = this.$moment()
        .add(1, "months")
        .format("MMMM");
      let items: IDownloadButtonTooltips[] = [];
      if (!this.scheduleDisabled) {
        items = items.concat([
          {
            id: 0,
            icon: "template",
            text: `Шаблон расписания на ${currentMonth}`
          },
          {
            id: 1,
            icon: "template",
            text: `Шаблон расписания на ${nextMonth}`
          }
        ]);
      }

      if (!this.badgeDisabled) {
        items.push({
          id: 2,
          icon: "employee",
          text: "Бейджи"
        });
      }
      return items;
    },
    scheduleDisabled(): boolean {
      return this.terminalId === undefined;
    },
    badgeDisabled(): boolean {
      return this.terminalId === undefined && !this.searchFilled;
    },
    disabled(): boolean {
      return (this.scheduleDisabled && this.badgeDisabled) || this.isLoading;
    },
    displayedEmployees(): Employee[] {
      return this.$cStore.employeeSearch.filteredEmployees;
    }
  },
  methods: {
    downloadHandler(id: number) {
      if (id in [0, 1]) {
        this.downloadSchedule(id);
      } else {
        this.downloadBadges();
      }
    },
    async downloadSchedule(monthId: number) {
      if (this.terminalId === undefined || this.isLoading) return;
      this.isLoading = true;
      let month = this.$moment();
      if (monthId === 1) {
        month.add("1", "month");
      }
      const response = await exportSchedule({
        from_date: month.startOf("month"),
        till_date: month.clone().endOf("month"),
        terminal: this.terminalId
      });
      this.isLoading = false;
      if (!response) {
        this.isError = true;
      } else {
        this.downloadFile(response, month);
      }
    },
    async downloadBadges() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        if (!this.displayedEmployees.length) throw "employees not found";
        await this.$cStore.badges.requireBadges(this.displayedEmployees);
      } catch (err) {
        this.isError = true;
        console.log("badges load error: ", err);
      }
      this.isLoading = false;
    },
    downloadFile(file: Blob, month: Moment) {
      const from_date = month
        .clone()
        .startOf("month")
        .format("DD-MM-YYYY");
      const till_date = month
        .clone()
        .endOf("month")
        .format("DD-MM-YYYY");

      const terminal_name = this.$cStore.terminal.terminalName;
      let url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${terminal_name}. Расписание с ${from_date} по ${till_date}.xlsx`;
      a.click();
      a.remove();
    }
  }
});
