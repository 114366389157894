






























import Vue from "vue";
import { TableText } from "@/ui/Table";
import { Loader } from "@/ui";
import { TableRow } from "./Table.vue";
import RowLayout from "./RowLayout.vue";
import Employee from "@/components/Employee.vue";
import Employee_svg from "@/assets/svg/employee.svg?inline";
import { Moment } from "moment";
import TimeCell from "./TimeCell.vue";

export default Vue.extend({
  name: "TableScheduleRow",
  props: {
    record: Object as () => TableRow,
    dateRange: Array as () => Moment[]
  },
  data: () => ({
    tooltipOpened: false,
    badgeLoading: false
  }),
  methods: {
    dateSlotName(index: number): string {
      return "date_" + (index + 1);
    },
    async downloadBadge() {
      if (this.badgeLoading) return;
      try {
        this.badgeLoading = true;
        await this.$cStore.badges.requireBadges(this.record.employee);
      } catch (err) {
        console.log("badges load error: ", err);
      }
      this.badgeLoading = false;
    }
  },
  components: {
    RowLayout,
    Employee,
    Employee_svg,
    TimeCell,
    Loader
  }
});
