





















import Vue from "vue";
import PageLayout from "@/components/pageWrap.vue";
import EmployeesSearch from "@/components/EmployeesSearch.vue";
import TableSchedule from "@/components/TableSchedule";
import ImportScheduleBtn from "@/components/ImportScheduleBtn.vue";
import ExportScheduleBtn from "@/components/ExportScheduleBtn.vue";
import { Moment } from "moment";
import { Route } from "vue-router";

const queryDateName = "date";

export default Vue.extend({
  name: "shedule",
  metaInfo: {
    title: "Расписание"
  },
  data: () => ({
    renderTable: false
  }),
  components: {
    PageLayout,
    TableSchedule,
    ImportScheduleBtn,
    ExportScheduleBtn,
    EmployeesSearch
  },
  computed: {
    fromDate(): Moment {
      return this.$cStore.schedule.fromDate;
    },
    tillDate(): Moment {
      return this.$cStore.schedule.tillDate;
    }
  },
  mounted() {
    this.processQueryDate();
  },
  methods: {
    processQueryDate() {
      const date = this.parseQueryDate();
      if (date) {
        const shift = this.getShiftByDate(date);
        this.$cStore.schedule.SHIFT_WEEK(shift);
        this.setQuery(date);
      } else {
        this.setQuery(this.fromDate);
      }
      this.renderTable = true;
    },
    parseQueryDate(): Moment | undefined {
      let query = this.$route.query[queryDateName];
      if (query && typeof query === "string") {
        const date = this.$moment(query, "DD.MM.YYYY");
        if (date.isValid()) {
          return date;
        }
      }
    },
    getShiftByDate(date: Moment): number {
      let weekShift = this.fromDate
        .clone()
        .startOf("day")
        .diff(date, "week", true);
      /*
        prev week: (0, 1] -> -1; 2x prev week: (1,2]  -> -2
        next week: (-2, -2] -> 1, 2x next week: [-2, -3) -> 2
      */
      return -1 * Math.ceil(weekShift);
    },
    setQuery(weekStartDate: Moment) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            [queryDateName]: weekStartDate.format("DD.MM.YYYY")
          }
        })
        .catch(err => {
          console.log("navigation error", err);
        });
    }
  },
  watch: {
    fromDate(newDate: Moment) {
      const currentQueryDate = this.parseQueryDate();
      //если fromDate изменилось, но в той же неделе - ничего не ставим(если дата в сторе есть)
      if (currentQueryDate) {
        const currentQueryDateShift = this.getShiftByDate(currentQueryDate);
        const newQueryDateShift = this.getShiftByDate(newDate);
        if (
          currentQueryDateShift !== newQueryDateShift ||
          !this.$cStore.routeQuery.query?.[queryDateName]
        ) {
          this.setQuery(newDate);
        }
      } else {
        this.setQuery(newDate);
      }
    }
  }
});
