













































import Vue from "vue";
import { TableRow } from "@/ui/Table";
export default Vue.extend({
  props: {
    isHeader: Boolean
  },
  computed: {
    colComponent() {
      return this.isHeader ? "th" : "td";
    }
  },
  components: {
    TableRow
  }
});
