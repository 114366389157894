








































































import { TextInputSearch, Loader } from "@/ui";
import TerminalItem from "@/components/TerminalItem.vue";
import { ITerminal, ITerminalUsers } from "@/types/terminal";
import { getTerminals } from "@/api/dashboard";
import { TerminalWithStatus } from "@/schemas/dashboard";
import Vue, { VueConstructor } from "vue";
import { debounce, orderBy } from "lodash";
import { TerminalModule, ALL_TERMS } from "@/store/modules/terminal";
import { Route } from "vue-router";

interface IComponentData {
  [propName: string]: any;
}
//TODO: можно вынести загрузку терминалов в app/index.vue beforeRouteEnter и избавиться от логики не загруженных терминалов
export default Vue.extend({
  components: {
    TextInputSearch,
    Loader,
    TerminalItem
  },
  data: function(): IComponentData {
    return {
      searchValue: "",
      showUsers: true,
      disableTooltips: false,
      tooltipsTimer: false,
      preSelectedIndex: null,
      inputFocus: false,
      isLoading: true,
      allTermsState: new ALL_TERMS()
    };
  },
  computed: {
    terminals(): TerminalWithStatus[] {
      const searchValue = this.searchValue.trim().toLowerCase();
      const filteredTerminals = this.termStore.terminals.filter(terminal => {
        let terminalName = terminal.name.toLowerCase();
        return terminalName.includes(searchValue);
      });
      return filteredTerminals;
    },
    allTerminalContent(): TerminalWithStatus {
      return {
        id: -1,
        name: "Все терминалы",
        address: "",
        timezone: "",
        is_online: true,
        employees_online: this.terminals.reduce((sum, term) => sum + term.employees_online, 0),
        employees_offline: this.terminals.reduce((sum, term) => sum + term.employees_offline, 0),
        last_ping: "",
        badge_logo: ""
      };
    },
    termStore(): TerminalModule {
      return this.$cStore.terminal;
    },
    activeTerminalId(): number {
      return this.termStore.terminalId ? this.termStore.terminalId : this.allTerminalContent.id;
    },
    allBtnShowed(): boolean {
      return this.searchValue === "";
    },
    sortedTerminals(): TerminalWithStatus[] {
      return orderBy(this.terminals, [o => o.employees_offline, o => o.name], ["desc", "asc"]);
    },
    activeTerminal(): TerminalModule["activeTerminal"] {
      return this.termStore.activeTerminal;
    }
  },
  methods: {
    getCurrentIndex(direction: string) {
      let currentIndex = this.preSelectedIndex;
      if (currentIndex === null) {
        if (direction === "up") {
          currentIndex = this.terminals.length;
        } else {
          currentIndex = this.allBtnShowed ? -2 : -1;
        }
      }
      return currentIndex;
    },
    moveHoverState(direction: string) {
      if (this.terminals.length == 0) return;
      let currentIndex = this.getCurrentIndex(direction);

      if (direction === "up") {
        this.preSelectedIndex = currentIndex === 0 ? this.terminals.length - 1 : currentIndex - 1;
      } else {
        if (currentIndex === this.terminals.length - 1) {
          this.preSelectedIndex = this.allBtnShowed ? -1 : 0;
        } else {
          this.preSelectedIndex = currentIndex + 1;
        }
      }
    },
    selectTerminal(terminal: TerminalModule["activeTerminal"]): void {
      this.termStore.SET_ACTIVE(terminal);
      this.searchValue = "";
      this.dropSelected();
    },
    terminalsScroll() {
      this.disableTooltips = true;
      clearTimeout(this.tooltipsTimer);
      this.tooltipsTimer = setTimeout(() => {
        this.disableTooltips = false;
      }, 300);
    },
    selectPreSelected() {
      let terminal = this.terminals[this.preSelectedIndex];
      if (terminal) {
        this.selectTerminal(terminal);
      }
    },
    dropSelected() {
      this.preSelectedIndex = null;
    },
    delegateFocusToInput() {
      let focusedInput = this.$refs.focusedInput;
      if (!focusedInput) return;
      (focusedInput as HTMLElement).focus();
      this.inputFocus = true;
    },
    searchClick(e: Event) {
      e.stopPropagation();
    },
    getRouteQueryTerminal(): number | null {
      let query = this.$route.query.terminal;
      let parsedQuery = Array.isArray(query) ? query[0] : query;
      return parsedQuery ? parseInt(parsedQuery) : null;
    },
    setRouteQuery() {
      let terminal: string | undefined;
      if (this.termStore.terminalId !== undefined) {
        terminal = String(this.termStore.terminalId);
      }
      this.$cStore.routeQuery.ADD_QUERY({ terminal });
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.termStore.fetchTerminals();
    this.isLoading = false;

    let queryTerminalId = this.getRouteQueryTerminal();
    let terminal = this.allTermsState;
    if (queryTerminalId !== null) {
      let t = this.termStore.getTerminal(queryTerminalId);
      if (t) {
        terminal = t;
      }
    }

    this.termStore.SET_ACTIVE(terminal);
  },
  watch: {
    activeTerminal: function(to, from) {
      this.setRouteQuery();
    },
    searchValue() {
      this.dropSelected();
    }
  }
});
