




































import Vue from "vue";
import RowLayout from "./RowLayout.vue";
import { TableText } from "@/ui/Table";
import Arrow_svg from "@/assets/svg/arrow-wrapped.svg?inline";
import { Moment } from "moment";
import { ScheduleModule } from "@/store/modules/schedule";

export default Vue.extend({
  props: {
    dateRange: Array as () => Moment[]
  },
  computed: {
    scheduleStore(): ScheduleModule {
      return this.$cStore.schedule;
    },
    nextWeekExists(): boolean {
      return this.scheduleStore.nextWeekExists;
    },
    prevWeekExists(): boolean {
      return this.scheduleStore.prevWeekExists;
    }
  },
  methods: {
    dateSlotName(index: number): string {
      return "date_" + (index + 1);
    },
    shiftDateRange(direction: "next" | "prev") {
      this.scheduleStore.shiftWeek(direction);
    }
  },
  components: {
    TableText,
    RowLayout,
    Arrow_svg
  }
});
