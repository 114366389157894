<template>
  <Wrap>
    <p class="terminalItemTooltip__text terminalItemTooltip__text-gray">Последнее соединение:</p>
    <p class="terminalItemTooltip__text">{{ time | moment("DD.MM.YYYY HH:mm") }}</p>
  </Wrap>
</template>

<script>
import Wrap from "./Wrap.vue";
export default {
  components: {
    Wrap
  },
  props: {
    time: Date
  }
};
</script>

<style lang="postcss" scoped>
.terminalItemTooltip {
  &__text {
    font-family: Inter;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    display: block;
    margin-bottom: 7px;
    &:last-child {
      margin-bottom: 0;
    }
    &-gray {
      color: #96989f;
    }
  }
}
</style>
