

























import Vue from "vue";
import { WrongTooltip } from "@/components/Tooltips";
import { Button as cButton } from "@/ui";
import { scheduleImport } from "@/api/dashboard";
import { TerminalModule } from "@/store/modules/terminal";
export default Vue.extend({
  components: {
    WrongTooltip,
    cButton
  },
  data: function() {
    return {
      isLoading: false,
      showError: false,
      fileTypes: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ]
    };
  },
  computed: {
    terminalId(): TerminalModule["terminalId"] {
      return this.$cStore.terminal.terminalId;
    },
    disabled(): boolean {
      return this.terminalId === undefined || this.isLoading || !this.$cStore.user.userCanEdit;
    }
  },
  methods: {
    async filesChanged() {
      const file = this.takeFile();
      if (!file || this.terminalId === undefined) return;
      (this.$refs.fileInput as HTMLInputElement).value = "";
      this.isLoading = true;

      const errors = await scheduleImport({
        terminal: this.terminalId,
        file
      });
      this.isLoading = false;
      if (errors) {
        this.showError = true;
      } else {
        this.$cStore.schedule.repeatGettingSchedule();
      }
    },
    takeFile(): File | false {
      const inputEl = this.$refs.fileInput as HTMLInputElement;
      const files = inputEl.files;
      if (!files || !files.length) return false;
      return files[0];
    }
  },
  watch: {
    disabled(isDisabled) {
      if (isDisabled) {
        this.showError = false;
      }
    }
  }
});
